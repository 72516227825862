<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/users/admins')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="userName" class="form-label required">
            اسم الموظف
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="userName"
            v-model="body.name"
            :class="{ 'is-invalid': isInvalid && !body.name }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="email" class="form-label required">
            البريد الالكتروني
            <span>*</span>
          </label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="body.email"
            :class="{ 'is-invalid': isInvalid && !body.email }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="userName" class="form-label required">
            اسم المستخدم
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="userName"
            v-model="body.userName"
            :class="{ 'is-invalid': isInvalid && !body.userName }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label required">
            الهاتف
            <span>*</span>
          </label>
          <input
            type="tel"
            class="form-control"
            id="phone"
            v-model="body.phone"
            :class="{ 'is-invalid': isInvalid && !body.phone }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="password" class="form-label required">
            كلمه المرور
            <span>*</span>
          </label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="body.password"
            :class="{ 'is-invalid': isInvalid && !body.password }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="password" class="form-label required">
            تأكيد كلمه المرور
            <span>*</span>
          </label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="body.confermedPassword"
            :class="{
              'is-invalid':
                (isInvalid && !body.confermedPassword) ||
                body.password != body.confermedPassword,
            }"
            required
          />
        </div>
      </div>

      <div class="col-md-6 text-right branches">
        <div class="mb-3">
          <label
            for="rolesId"
            class="form-label required"
            :style="{
              color: isInvalid && body.branches.length == 0 ? 'red' : '',
            }"
          >
            الفروع
            <span>*</span>
          </label>

          <div
            class="p-field-checkbox"
            v-for="item of branchesList"
            :key="item.id"
          >
            <Checkbox
              :id="item.id"
              name="branches"
              :value="item"
              v-model="body.branches"
            />
            <label :for="item.id">{{ item.name }}</label>
          </div>
        </div>
      </div>
      <div class="col-md-6 text-right branches">
        <div class="mb-3">
          <label
            for="rolesId"
            class="form-label required"
            :style="{
              color: isInvalid && body.warehouses.length == 0 ? 'red' : '',
            }"
          >
            المستودعات
          </label>

          <div
            class="p-field-checkbox"
            v-for="item of warehousesList"
            :key="item.id"
          >
            <Checkbox
              :id="item.id"
              name="warehouses"
              :value="item"
              v-model="body.warehouses"
            />
            <label :for="item.id">{{ item.name }}</label>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="rolesId" class="form-label required">
            الصلاحيات
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="rolesId"
            name="rolesId"
            v-model.number="body.rolesId"
            :class="{ 'is-invalid': isInvalid && !body.rolesId }"
            required
          >
            <option v-for="item of rolesList" :key="item.id" :value="item.id">{{
              item.name
            }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="administrationId" class="form-label required">
            الاداره
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="administrationId"
            name="administrationId"
            v-model.number="body.administrationId"
            :class="{ 'is-invalid': isInvalid && !body.administrationId }"
            required
          >
            <option
              v-for="item of administrationList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="sectionsId" class="form-label required">
            القسم
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="sectionsId"
            name="sectionsId"
            v-model.number="body.sectionsId"
            :class="{ 'is-invalid': isInvalid && !body.sectionsId }"
            required
          >
            <option
              v-for="item of sectionsList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="employeesId" class="form-label required">
            الوظيفه
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="employeesId"
            name="employeesId"
            v-model.number="body.employeesId"
            :class="{ 'is-invalid': isInvalid && !body.employeesId }"
            required
          >
            <option
              v-for="item of employeesList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="note" class="form-label">
            ملاحظات
          </label>
          <b-form-textarea
            id="textarea-small"
            name="note"
            v-model="body.note"
            placeholder=""
          ></b-form-textarea>
        </div>

        <div class="mb-3 text-center">
          <Button
            label="تعديل"
            icon="pi pi-pencil" v-tooltip="'تعديل'"
            class="p-ml-2 p-button-info"
            @click="update()"
            v-if="id"
            :disabled="disabledAdd"
          />
          <Button
            v-else
            label="اضافة جديد"
            icon="pi pi-plus" v-tooltip="'اضافه جديد'"
            class="p-ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        userName: null,
        email: null,
        password: null,
        phone: null,
        userNotes: null,
        name: null,
        confermedPassword: null,
        rolesId: null,
        administrationId: null,
        sectionsId: null,
        employeesId: null,
        branches: [],
        warehouses: [],
      },
      id: null,
      rolesList: [],
      administrationList: [],
      sectionsList: [],
      employeesList: [],
      branchesList: [],
      warehousesList: [],
      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    save() {
      if (
        this.body.userName &&
        this.body.email &&
        this.body.password &&
        this.body.confermedPassword &&
        this.body.password == this.body.confermedPassword &&
        this.body.name &&
        this.body.rolesId &&
        this.body.administrationId &&
        this.body.sectionsId &&
        this.body.employeesId &&
        this.body.branches.length > 0
      ) {
        this.disabledAdd = true;
        delete this.body.confermedPassword;
        this.$http.post(`users`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافة بنجاح',
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push('/admin/users/admins');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
    update() {
      if (
        this.body.userName &&
        this.body.email &&
        this.body.name &&
        this.body.rolesId &&
        this.body.administrationId &&
        this.body.sectionsId &&
        this.body.employeesId
        // && this.body.branches.length > 0
      ) {
        this.disabledAdd = true;
        delete this.body.confermedPassword;
        if (this.body.password == null) {
          delete this.body.password;
        }

        this.$http.put(`users/${this.id}`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push('/admin/users/admins');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
     if (!this.$checkRoles('adminsAdd')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`branches`).then((res) => {
      this.branchesList = res.data;
    });
      this.$http.get(`roles`).then((res) => {
        this.rolesList = res.data;
      });
    this.$http.get(`warehouse`).then((res) => {
      this.warehousesList = res.data;
    });
    this.$http.get(`administration`).then((res) => {
      this.administrationList = res.data;
    });
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`users/${this.id}`).then(
        (res) => {
          this.body.rolesId = res.data.rolesId.id;
          this.body.administrationId = res.data.administrationId.id;
          this.body.sectionsId = res.data.sectionsId.id;
          this.body.employeesId = res.data.employeesId.id;
          this.body.branches = res.data.branches;

          this.body.warehouses = res.data.warehouses;

          this.body.name = res.data.name;
          this.body.userName = res.data.userName;
          this.body.email = res.data.email;

          this.body.phone = res.data.phone;
          this.body.userNotes = res.data.userNotes;

          // for (let item of res.data.branches) {
          //   const xItem=Object.assign(item)
          //   this.body.branches.push({ ...xItem, usersId: Number(this.id) });
          // }
          // console.log(this.body.branches);
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
  watch: {
    'body.administrationId'(val) {
      this.$http.get(`sections?administrationId=${val}`).then((res) => {
        this.sectionsList = res.data;
      });
    },
    'body.sectionsId'(val) {
      this.$http.get(`employees?sectionsId=${val}`).then((res) => {
        this.employeesList = res.data;
      });
    },
  },
};
</script>

<style></style>

<style>
.branches label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
